
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';

function search() {
  window.location.href = "/english/gsearch";
}

function Content(props){
  var name = props.name;
  var url = props.url;
  return ( 
      <NavDropdown.Item href={url}>{name} </NavDropdown.Item>
  );
}


function ContentList(props){
  var title = props.title;
  var id = props.id;
  const expand="sm";
  return ( 
      <NavDropdown title={title}  key={id}  id={`offcanvasNavbarDropdown-expand-${expand}`}>
         {props.children}
      </NavDropdown>
      
  );
}

function SearchBottom(props) {
  return ( 
      <>
          <button type="button" alt="sipa search"
            className="botton_search p-button-raised p-button-rounded mysearchicon"
            title="open window for sipa search."  onClick={()=>search()}>
            <i className="bi bi-search"></i><span className="sr-only">search</span>
          </button>
      </>
      
  );
}

export default function Mnuinfo() {
  // console.log(window.data1)
  // var dataList = props.data;
  // console.log(props.data)
  // console.log("===============")
  // console.log(window.data1)
  let loading = true;
  let dataList;
  if(window.data1 != undefined){
    dataList = window.data1;
    loading = false
  }  
  let clist = []
  if ( !loading ){
    clist = dataList.map((data, i) => {
      return (
          <ContentList key={data.title} title={data.title}>
              {data.data.map((eachdata, i) => {
                  return <Content key={eachdata.name} name={eachdata.name} url={'/english/'+eachdata.link}></Content>
              })}
          </ContentList>
      )
  })
  }
  return (
    <>
      {['sm'].map((expand) => (
        <Navbar key={expand}  expand={expand} className="mb-3">
          <Container fluid>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="start"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-2">
                {clist}
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            <SearchBottom></SearchBottom>
          </Container>
        </Navbar>
      ))}
    </>
  );
}
