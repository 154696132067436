import React from 'react';
import ReactDOM from 'react-dom/client';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
//import './styles/default-ocean-blue.css'
import './styles/bootstrap.min.css'
import './assets/vendor/bootstrap-icons/bootstrap-icons.css'
import './css/headerstyle.css'; 
import './css/indexstyle.css';
import './css/page.css'; 
import './css/framestyle.css'; 
import ReactGA from "react-ga4";

ReactGA.initialize([
  {trackingId: "G-HQ3DMWV7SQ",}
]);

// await getRouter()
// console.log(DataRoot)
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>
);
reportWebVitals();