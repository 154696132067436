import React from "react";
import { useState, useEffect, lazy } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Layout from "./components/layout/layout";
import Header from "./components/layout/header";
// import Home from "./pages/Home";
// import HSPIntroduction from "./pages/HSPIntroduction";
// import WhyHSP from "./pages/whyHSP"
// import Visits from "./pages/Visits";
// import InvestmentApplicationForms from "./pages/InvestmentApplicationForms"
// import PublicFacilitiesMaintenance from "./pages/PublicFacilitiesMaintenance"
// import SummaryOfInvestmentPlan from "./pages/SummaryOfInvestmentPlan"
// import Regulations from "./pages/Regulations"
// import Statistics from "./pages/Statistics"
// import AnnualReports from "./pages/AnnualReports"
// import NewList from "./pages/newList"
// import NewView from "./pages/newView"
// import SpotlightList from "./pages/SpotlightList"
// import SpotlightView from "./pages/SpotlightView"
// import HsinchuSciencePark from "./pages/HsinchuSciencePark"
// import JhunanSciencePark from "./pages/JhunanSciencePark"
// import LongtanSciencePark from "./pages/LongtanSciencePark"
// import TongluoSciencePark from "./pages/TongluoSciencePark"
// import YilanSciencePark from "./pages/YilanSciencePark"
// import BiomedicalPark from "./pages/BiomedicalPark"
// import ParkBureau from "./pages/ParkBureau"
// import DirectorGeneral from "./pages/DirectorGeneral"
// import InnovativeRD from "./pages/InnovativeRD"
// import Sitemap from "./pages/Sitemap"
// import NotFound from "./pages/NotFound";
// import Gsearch from "./pages/Gsearch"; 
// import OpenGovernment from "./pages/OpenGovernment";
// import SecurityPolicy from "./pages/SecurityPolicy";
// import PrivacyPolicy from  "./pages/PrivacyPolicy";
// import Site from "./pages/Site";
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'))
const SecurityPolicy = lazy(() => import('./pages/SecurityPolicy'))
const OpenGovernment = lazy(() => import('./pages/OpenGovernment'))
const Gsearch = lazy(() => import('./pages/Gsearch'))
const Sitemap = lazy(() => import('./pages/Sitemap'))
const SpotlightView = lazy(() => import('./pages/SpotlightView'))
const SpotlightList = lazy(() => import('./pages/SpotlightList'))
const NewView = lazy(() => import('./pages/newView'))
const NewList = lazy(() => import('./pages/newList'))
const Regulations = lazy(() => import('./pages/Regulations'))
const SummaryOfInvestmentPlan = lazy(() => import('./pages/SummaryOfInvestmentPlan'))

const HsinchuSciencePark = lazy(() => import('./pages/HsinchuSciencePark'))
const JhunanSciencePark = lazy(() => import('./pages/JhunanSciencePark'))
const LongtanSciencePark = lazy(() => import('./pages/LongtanSciencePark'))
const TongluoSciencePark = lazy(() => import('./pages/TongluoSciencePark'))
const YilanSciencePark = lazy(() => import('./pages/YilanSciencePark'))
const BiomedicalPark = lazy(() => import('./pages/BiomedicalPark'))
const ParkBureau = lazy(() => import('./pages/ParkBureau'))
const DirectorGeneral = lazy(() => import('./pages/DirectorGeneral'))
const InnovativeRD = lazy(() => import('./pages/InnovativeRD'))

const HSPIntroduction = lazy(() => import('./pages/HSPIntroduction'))
const WhyHSP = lazy(() => import('./pages/whyHSP'))
const Visits = lazy(() => import('./pages/Visits'))
const InvestmentApplicationForms = lazy(() => import('./pages/InvestmentApplicationForms'))
const PublicFacilitiesMaintenance = lazy(() => import('./pages/PublicFacilitiesMaintenance'))

const Home = lazy(() => import('./pages/Home'))
const Statistics = lazy(() => import('./pages/Statistics'))
const AnnualReports = lazy(() => import('./pages/AnnualReports'))
const NotFound = lazy(() => import('./pages/NotFound'))

const CommonPage = lazy(() => import('./pages/CommonPage'))

export default function App() {
  // console.log(props)

  // 連結、標題、對應後端資料serno
  const [dataList, setData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_DEFAULT_URL + '/englishadmin2/api/listMenuFrontController', {
        method: "POST",
      }
      );
      const data = await response.json();
      sessionStorage.setItem("App", JSON.stringify(data))
      setData(data);
    };
    // fetchData1();
    try {
      let expireTime = Date.parse(sessionStorage.getItem("AppExpire"))
      //初始化
      if (isNaN(expireTime)) {
        sessionStorage.setItem("AppExpire", new Date())
        fetchData();
      } else {
        let nowTime = new Date()
        // 30秒內不更新
        if (nowTime - expireTime <= 30000) {
          if (sessionStorage.getItem("App") == null) {
            fetchData();
          } else {
            setData(  JSON.parse( sessionStorage.getItem("App")));
          }
        } else {
          fetchData();
          sessionStorage.setItem("AppExpire", nowTime);
        }
      }
    } catch (error) {
      console.log("Fetch error")
    }
  }, []);


  // 處理轉換成前端應用路徑 
  var dynamicPaths = []

  // 拆解API得到的外層目錄
  if (dataList !== undefined) {
    dataList.forEach(element2 => {
      // var tmpPaths = []
      if (element2.data != null) {
        element2.data.forEach(element => {
          if (element.name === "Why Hsinchu Science Park") {
            dynamicPaths.push(<Route path="whyHSP" element={<WhyHSP serno={element.serno} />} key={element.name} />)
            element.link = "whyHSP"
          }
          else if (element.name === "Hsinchu Science Park") {
            dynamicPaths.push(<Route path="HsinchuSciencePark" element={<HsinchuSciencePark serno={element.serno} />} key={element.name} />);
            element.link = "HsinchuSciencePark"
          }
          else if (element.name === "Jhunan Science Park") {
            dynamicPaths.push(<Route path="JhunanSciencePark" element={<JhunanSciencePark serno={element.serno} />} key={element.name} />);
            element.link = "JhunanSciencePark"
          }
          else if (element.name === "Longtan Science Park") {
            dynamicPaths.push(<Route path="LongtanSciencePark" element={<LongtanSciencePark serno={element.serno} />} key={element.name} />);
            element.link = "LongtanSciencePark"
          }
          else if (element.name === "Tongluo Science Park") {
            dynamicPaths.push(<Route path="TongluoSciencePark" element={<TongluoSciencePark serno={element.serno} />} key={element.name} />);
            element.link = "TongluoSciencePark"
          }
          else if (element.name === "Yilan Science Park") {
            dynamicPaths.push(<Route path="YilanSciencePark" element={<YilanSciencePark serno={element.serno} />} key={element.name} />);
            element.link = "YilanSciencePark"
          }
          else if (element.name === "Biomedical Park") {
            dynamicPaths.push(<Route path="BiomedicalPark" element={<BiomedicalPark serno={element.serno} />} key={element.name} />);
            element.link = "BiomedicalPark"
          }
          else if (element.name === "Introduction") {
            dynamicPaths.push(<Route path="Introduction" element={<HSPIntroduction serno={element.serno} key={element.name} />} />)
            element.link = "Introduction"
          }
          else if (element.name === "Park Bureau") {
            dynamicPaths.push(<Route path="ParkBureau" element={<ParkBureau serno={element.serno} />} key={element.name} />)
            element.link = "ParkBureau"
          }
          else if (element.name === "Director-General") {
            dynamicPaths.push(<Route path="DirectorGeneral" element={<DirectorGeneral serno={element.serno} />} key={element.name} />)
            element.link = "DirectorGeneral"
          }
          else if (element.name === "Innovative R&D") {
            dynamicPaths.push(<Route path="InnovativeRD" element={<InnovativeRD serno={element.serno} />} key={element.name} />)
            element.link = "InnovativeRD"
          }
          else if (element.name === "Investment Application Forms") {
            dynamicPaths.push(<Route path="InvestmentApplicationForms" element={<InvestmentApplicationForms serno={element.serno} />} key={element.name} />)
            element.link = "InvestmentApplicationForms"
          }



          else if (element.name === "Visits") {
            dynamicPaths.push(<Route path="Visits" element={<Visits />} key={element.name} />)
            element.link = "Visits"
          }
          else if (element.name === "Public Facilities Maintenance") {
            dynamicPaths.push(<Route path="PublicFacilitiesMaintenance" element={<PublicFacilitiesMaintenance />} key={element.name} />)
            element.link = "PublicFacilitiesMaintenance"
          }
          else if (element.name === "Regulations") {
            dynamicPaths.push(<Route path="Regulations" element={<Regulations />} key={element.name} />)
            element.link = "Regulations"
          }
          else if (element.name === "Statistics") {
            dynamicPaths.push(<Route path="Statistics" element={<Statistics />} key={element.name} />)
            element.link = "Statistics"
          }
          else if (element.name === "Annual Reports") {
            dynamicPaths.push(<Route path="AnnualReports" element={<AnnualReports />} key={element.name} />)
            element.link = "AnnualReports"
          }else{
            // console.log(element)
            let commonpath = element.name.replace(" ","")
            element.link = commonpath
            dynamicPaths.push(<Route path={commonpath} element={<CommonPage serno={element.serno} menu={element2} rootdata={element} name={element.name}/>} key={element.name}/>)
          }

        })
      }
    });
  }
  // console.log(dataList)
  // 將 結構目錄 存在全域
  window.data1 = dataList

  return (
    // <BrowserRouter >
    <BrowserRouter basename="/english">
    <React.Suspense fallback={<><Header></Header></>}>
      <Routes>
        {dynamicPaths}
        <Route path="/" element={<Home />} />
        <Route path="SummaryOfInvestmentPlan" element={<SummaryOfInvestmentPlan />} />
        <Route path="Regulations" element={<Regulations />} />
        <Route path="newList" element={<NewList />} />
        <Route path="newView" element={<NewView />} />
        <Route path="SpotlightList" element={<SpotlightList />} />
        <Route path="SpotlightView" element={<SpotlightView />} />
        <Route path="Sitemap" element={<Sitemap root = { dataList }/>} />
        <Route path="Gsearch" element={<Gsearch/>} />
        <Route path="OpenGovernment" element={<OpenGovernment/>} />
        <Route path="SecurityPolicy" element={<SecurityPolicy/>} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      </React.Suspense>
    </BrowserRouter>
  );
}
