import * as React from "react";
import Button from 'react-bootstrap/Button';

export default function Topinfo() {
  function mysitemap() {
    window.location.href = "/english/Sitemap";
  }
  function myemail() {
    window.location.href = "mailto:scott@sipa.gov.tw";
  }
  function facebook() {
    window.location.href = "https://www.facebook.com/hsipa/";
  }
  function youtube() {
    window.location.href = "https://www.youtube.com/user/sipa10310";
  }
  function tosipa() {
    window.location.href = "https://www.sipa.gov.tw/";
  }
  function changefontsize(size) {
    switch (size) {
      case 'small': document.querySelector('html').style.fontSize = '0.89rem';
      break;
      case 'medium': document.querySelector('html').style.fontSize = '1.0rem';
      break;
      case 'large': document.querySelector('html').style.fontSize = '1.15rem';
      break;
      default:  return 'medium';
    }
  }
  

  return (
    <>
        <div className="heder_t"></div>
        <div className="myheader">
        <div className="p-grid topinfo p-jc-end2 smflexstart">
          <div className="accessDiv">
          <a href="#U" id="AU" name="U" className="access_p" title="List of links at top of page for main connections available in this web site." ><span className="accessDivV">:::</span></a>
          </div>
          <Button
            className="mysitemap"
            label="sitemap"
            onClick={()=>mysitemap()}>
            Sitemap
          </Button>
          <div className="lineinfo p-as-center">|</div>
          <Button
            title="Email btn"
            label="Email"
            className="mysitemap p-as-center"
            onClick={()=>myemail()}>
            Email
          </Button>
          <div className="lineinfo p-as-center">|</div>
          <button
            title="fb btn"
            type="button"
            className="btn-primary2 mysitemap"
            label=" "  onClick={()=>facebook()}>
            <img
              alt="facebook icon"
              className="facebookicon"
              src="/english/images/facebook.png"
            />
          </button>
          <button
            title="youtube btn"
            type="button"
            className="btn-primary2 mysitemap"
            label=" "  onClick={()=>youtube()}>
            <img
              alt="youtube icon"
              className="youtubicon"
              src="/english/images/youtube.png"
            />
          </button>
          <div className="hiddenitem" style={{height: '42px',width: '42px'}}></div>
        <button title="btn SIPA link" type="button" label="中文" className="btn btn-primary2 mysitemap" onClick={()=>tosipa()}>中文</button>
        <div className="lineinfo p-as-center headeritem hiddenitem">|</div>
        <div label="Font" className="mysitemap fontinfo hiddenitem">Font</div>
        <button title="btn small A" type="button" label="A-" className="fontsmall headeritem fontitem  hiddenitem"  onClick={()=>changefontsize('small')}>A</button>
        <button title="btn medium A" type="button" label="A" className="fontmedium headeritem fontitem  hiddenitem" onClick={()=>changefontsize('medium')}>A</button>
        <button title="btn large A" type="button" label="A+" className="fontlarge headeritem fontitem  hiddenitem" onClick={()=>changefontsize('large')}>A</button>
        </div>
      </div>
    </>
      
  );
}
