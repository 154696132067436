
import Topinfo from './topinfo';
import Menuinfo from './menu';
// import MenuItem from "../../example_json/menu.json";

function accessKey(akey){
    document.getElementById("AU").accessKey = akey;
    }


export default function Header() { 
	return ( 
    <header onLoad={()=>accessKey("U")}>
      <Topinfo />
      <div className="p-d-flex p-jc-between lay2 header_con">
      <div className="headerlogo logo_con">
        <h1>
        <a href="/english" className="headerlogo" style={{position: 'absolute'}}> 
          <img className="logoimg" alt="SIPA LOGO" src="/english/images/logo.png" /> </a>
          </h1>
      </div>
     
      <div className="p-d-flex p-jc-center navbarinfo">
            <Menuinfo></Menuinfo>
      </div>
      </div>
      </header>    
	)
}
